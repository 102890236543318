const React = require('react');
const PropTypes = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const translate = require('../../translation');
const Page = require('../../components/Page');
const Resizer = require('../../components/Resizer');
const { CHANGE_IFRAME_SIZE } = require('../../spa/actions/types');
const sizeActions = require('../../spa/actions/size');
const Oops = require('../../components/Oops');
const Title = require('../../components/Title');
const IconTimeOut = require('../../components/icons/TimeOut');
const { convertTime } = require('../../utils/Date');

class Expired extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.getDateText = this.getDateText.bind(this);
    this.translations = translate(i18n);

    this.state = {
      title: '',
      subTitle: '',
      show: false,
    };
  }

  componentDidMount() {
    const { title, subTitle } = this.getDateText();
    // calculate the date from the client
    this.setState({
      subTitle,
      title,
      show: true,
    });
  }

  getDateText() {
    const { hasExpired, isUnavailable, expirationDateFrom, expirationDateTo } = this.props;

    let subTitle;
    let title;

    if (hasExpired) {
      title = this.translations.WHAT_YOU_WANTED_TO_PAY_IS_NO_LONGER_AVAILABLE;
      if (expirationDateTo) {
        subTitle = this.translations.IT_WAS_AVAILABLE_UNTIL_X_HOURS(convertTime(expirationDateTo));
      }
    } else if (isUnavailable) {
      title = this.translations.LESS_AND_LESS_IS_MISSING;
      if (expirationDateFrom) {
        subTitle = this.translations.THE_PURCHASE_YOU_WANT_WILL_BE_AVAILABLE_FROM(convertTime(expirationDateFrom));
      } else {
        subTitle = this.translations.THE_PURCHASE_YOU_WANT_IS_NOT_YET_AVAILABLE_TRY_AGAIN_LATER;
      }
    }
    return {
      title,
      subTitle,
    };
  }

  render() {
    const { stepTitle, trackingPath, analytics, purpose, deviceType, brandName, backUrl, currentStep } = this.props;

    const buttonLabel = brandName ? this.translations.RETURN_TO(brandName) : this.translations.RETURN_TO_SITE;

    // Force resize on wallet button. Timedout in order to components render to happen
    setTimeout(() => {
      const size = 1000;
      // eslint-disable-next-line no-unused-expressions
      this.props.sizeActions[CHANGE_IFRAME_SIZE](size);
    }, 500);

    return (
      <Page
        title={stepTitle}
        currentStep={currentStep}
        trackingPath={trackingPath}
        analytics={analytics}
        deviceType={deviceType}
      >
        {this.state.show
          && (
            <div className="group-generic-message expired">
              <Oops className="pref-expired">
                <IconTimeOut />
              </Oops>
              <Title text={this.state.title} />
              <h2 className="title-h3">{this.state.subTitle}</h2>
              {backUrl && (!purpose || purpose !== 'wallet_purchase')
                && (
                  <a
                    href={backUrl}
                    target="_top"
                    className="andes-button andes-button--link button-link"
                  >
                    {buttonLabel}
                  </a>
                )}
              <Resizer />
            </div>
          )}
      </Page>
    );
  }
}

/**
 * Prop Types
 */
Expired.propTypes = {
  flow: PropTypes.object, // eslint-disable-line
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  stepTitle: PropTypes.string,
  brandName: PropTypes.string,
  backUrl: PropTypes.string,
  expirationDateFrom: PropTypes.string,
  currentStep: PropTypes.string,
  pageData: PropTypes.object, // eslint-disable-line
  trackingPath: PropTypes.string,
  analytics: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  deviceType: PropTypes.string,
  stepActions: PropTypes.object, // eslint-disable-line
  history: PropTypes.object, // eslint-disable-line
};

/**
 * Default Props
 */
Expired.defaultProps = {
  flow: null,
  stepTitle: '',
  currentStep: '',
  expirationDateFrom: '',
  brandName: '',
  backUrl: '',
  pageData: {},
  trackingPath: 'failure',
  analytics: {},
  deviceType: '',
  stepActions: {},
  i18n: {
    gettext: t => t,
  },
  history: {},
};

const mapDispatchToProps = dispatch => ({
  sizeActions: bindActionCreators(sizeActions, dispatch),
});

module.exports = injectI18n(connect(null, mapDispatchToProps)(Expired));
